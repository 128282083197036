//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Pie } from "@antv/g2plot";
export default {
  name: "ChatKeywordRank",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData(val) {
      if (this.chart) {
        this.chart.changeData(val);
      }
    },
  },
  methods: {
    renderChart() {
      if (this.chart) {
        return;
      }
      this.chart = new Pie(this.$refs.chart, {
        appendPadding: 10,
        data: this.chartData,
        angleField: "TotalCount",
        colorField: "Keyword",
        radius: 1,
        innerRadius: 0.6,
        legend: false,
        label: false,
        interactions: [
          { type: "element-selected" },
          { type: "element-active" },
        ],
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textAlign: "center",
              textOverflow: "ellipsis",
              fontSize: 18,
            },
            content: "关键词TOP5",
          },
        },
      });
      this.chart.update({
        theme: {
          styleSheet: {
            brandColor: "#B8E1FF",
            paletteQualitative10: [
              "#B8E1FF",
              "#9AC5FF",
              "#7DAAFF",
              "#5B8FF9",
              "#3D76DD",
              "#085EC0",
              "#0047A5",
              "#00318A",
              "#001D70",
            ],
            paletteQualitative20: [
              "#B8E1FF",
              "#9AC5FF",
              "#7DAAFF",
              "#5B8FF9",
              "#3D76DD",
              "#085EC0",
              "#0047A5",
              "#00318A",
              "#001D70",
            ],
          },
        },
      });
      this.chart.on("element:click", (e) => void this.$emit("elementClick", e));
      this.chart.render();
    },
  },
  mounted() {
    this.renderChart();
  },
};
